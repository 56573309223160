<template>
  <div>
    <!--			用户评价-->
    <div v-if="TabActive === 'course_comment'" class="discuss">
      <div class="discuss-sun">全部评价（{{ total }}）</div>
      <!--            <div class="tags">-->
      <!--                <span v-for="(item,index) in tags" :key="index" :class="idx == index?'active':''" @click="tabNav(index)">{{item.label}}</span>-->
      <!--            </div>-->
      <div class="discuss-list">
        <div v-for="(cus, ii) in lists" :key="ii" class="list">
          <div class="list-head">
            <router-link
              v-if="cus.userAvatar"
              :to="`/userPage?userId=${cus.userId}`"
            >
              <img :src="cus.userAvatar" class="logo" alt="" />
            </router-link>

            <img v-else src="@/assets/images/scb/cson-logo.png" class="logo" />
            <span class="courseName">{{ cus.username }}</span>

            <!--                        <h2 class="date">{{ cus.commentTime }}</h2>-->
          </div>
          <div class="list-body">{{ cus.content }}</div>
        </div>
      </div>
      <div class="pagenext">
        <el-pagination
          v-if="total"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          :total="total"
          background
          layout="prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!--			内容安排-->
    <div
      v-if="TabActive === 'course_content'"
      class="curson-con course-catalog"
    >
      <!--      左侧悬浮-->
      <div class="right-chapter-content-left">
        <div :class="leftNavClass">
          <ul>
            <li
              v-for="(nav, jj) in steps"
              :key="jj"
              :class="jj == TabCur ? 'active' : ''"
              @click="tabSelect(jj)"
            >
              <a>{{ nav.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="right-chapter-content-right">
        <!-- 小标签 -->
        <div
          v-for="(item, index) in steps"
          :id="'step' + index"
          :key="index"
          :ref="`step${index}`"
          class="step-box"
        >
          <div class="step-name">{{ item.name }}</div>
          <div v-for="(week, jj) in item.weeks" :key="jj" class="week-box">
            <div class="week-name">
              <img
                class="icon"
                src="@/assets/images/scb/week-title-icon.png"
              />{{ week.weekname }}
            </div>

            <div v-if="week.children" class="detial">
              <div class="dis">{{ week.dis }}</div>
              <div class="title">课程安排：</div>
              <ul>
                <li v-for="(child, ii) in week.children" :key="ii">
                  {{ child }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassComments } from "../../service";
import courseDatas from "../../utils/courseDatas";

export default {
  name: "courseDetail",
  props: {
    TabActive: 0,
    courseID: "",
  },
  mixins: [courseDatas],
  data() {
    return {
      total: 0,
      pageSize: 15,
      lists: [],
      steps: [],
      stickyToggle: false,
      stickyEnabled: true,
      bantxt: "北美SDE算法速成班",
      dr: 3,
      br: 3,
      txShow: true,
      curId: 1,
      tradio: 3,
      Pselect: "1",
      img: require("../../assets/images/scb/banner.jpg"),
      form: {
        region: "v1",
      },
      TabCur: 0,
      idx: "",
      currentPage3: 1,
      leftNavClass: "left-nav",
      scroll: "",

      flag: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.leftNavFun);

    this.flag == true
      ? window.addEventListener("scroll", this.updateNavColor, true)
      : window.removeEventListener("scroll", this.updateNavColor, true);
  },
  created() {},
  watch: {
    flag(newv, oldv) {
      console.log(111111111111111);
    },
    TabActive: function (newVal, oldVal) {
      console.log("tabIndex change:", newVal, oldVal);
      switch (newVal) {
        case "course_intro":
          break;
        case "course_content": //课程安排
          this.setCourseContent();
          break;
        case "course_mode":
          break;
        case "course_comment": //评论
          this.getComments();
          break;
        case "course_question": //
          break;
        case "course_price":
          window.location.hash = "#tt-price";
          break;
      }
    },
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(index) {
      this.currentPage3 = index;
      this.getComments();
      window.scrollTo(0, 0);
    },
    getComments() {
      getClassComments(this.courseID, this.pageSize, this.currentPage3)
        .then((res) => {
          console.log("评论内容：", res);
          this.total = res.result.total;
          if (!res.result.code) {
            this.lists = res.result.records;
          }
        })
        .catch((err) => {
          console.log("评论内容错误：", err);
        });
    },
    setCourseContent() {
      console.log(this.courseID, this.exerciseClass);
      switch (this.courseID) {
        case "1": //leetcode100
          // this.steps = this.leetcode100;
          break;
        case "2": //LeetCode千题讲解视频
          this.steps = this.leetCodeVideoClass;
          break;
        case "3": //北美SDE算法速成班
          this.steps = this.crashClass;
          break;
        case "4": //求职面试算法班
          this.steps = this.algorithmFoundationClass;
          break;
        case "5":
          this.steps = this.algorithmExerciseClass;
          break;
        case "9": //求职面试刷题班
          this.steps = this.exerciseClass;
          break;
        case "6": //北美速成
          this.steps = this.usExerciseClass;
          break;
        case "7": // 旗舰班
          this.steps = this.VIPClass;
          break;
        case "8": // 面向对象设计班
          this.steps = this.OODDesignPattern;
          break;
        case "11": // 北美CS留学求职规划班
          this.steps = this.CsJobEducation;
          break;
        case "12": // 项目班
          this.steps = this.usVIPProject;
          break;
        case "13": // LC免费300题
          this.steps = this.leetCodeFree300;
			break;
		  
		  
      }
      console.log("steps", this.steps);
    },
    tabChange(index) {
      this.TabActive = index;
    },

    tabNav(index) {
      this.tags.forEach((element, i) => {
        if (i === index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.idx = index;
    },
    open(index) {
      this.lists[index].isopen = !this.lists[index].isopen;
    },
    openit(index) {
      this.questionList[index].isopen = !this.questionList[index].isopen;
    },
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    // 随着页面滚动导航改变颜色
    updateNavColor: function () {
      if (!this.flag) return;

      let len = this.steps.length;

      if (len == 0) {
        return;
      }

      let height = window.screen.height + 100;
      for (let i = 0; i < len; i++) {
        if (!this.$refs[`step${i}`] || this.$refs[`step${i}`].length == 0) {
          continue;
        }
        let top = this.$refs[`step${i}`][0].getBoundingClientRect().top;
        if (top > 100 && top < height) {
          this.TabCur = i;
          break;
        } else if (top > height) {
          this.TabCur = i - 1;
          break;
        }
      }
    },
    // 点击左侧导航改变颜色
    tabSelect(index) {
      this.TabCur = index;
      this.flag = false;
      this.jumpSteps(index);
    },
    // 点击左侧导航跳到最上面
    jumpSteps(index) {
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector(`#step${index}`).offsetTop + 400;
      });
      setTimeout(() => (this.flag = true), 500);
    },
    // 左边悬浮窗状态
    leftNavFun() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll >= 500) {
        this.leftNavClass = "left-nav2";
      }
      if (this.scroll < 500) {
        this.leftNavClass = "left-nav";
      }
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/courses/common.css");


</style>
